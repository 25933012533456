import React, {useState, useEffect, useContext} from 'react'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import queryString from 'query-string'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import {makeStyles} from '@material-ui/core/styles'
import {ResourceList} from 'components/molecules/ResourceList'
import {EmptyResources} from 'components/molecules/EmptyResources'
import * as Api from 'lib/api'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'


const useStyles = makeStyles(theme => ({
  toolbar: {
    flexDirection: 'row-reverse',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}))

function CallToAction() {
  const classes = useStyles()
  return (
    <Button to="/users/invite" component={Link} variant='contained' color='primary'>
      <PersonAddIcon className={classes.leftIcon}/>
      <FormattedMessage id="inviteUser" defaultMessage='Invite user'/>
    </Button>
  )
}

export function UsersPage({location}) {
  const [users, setUsers] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const {tenant} = useContext(AuthenticationContext)

  const classes = useStyles()

  const qs = queryString.parse(location.search)

  useEffect(() => {
    async function fetchData() {
      var data = await Api.getTenantUsers(tenant.id, qs.page)
      setUsers(data.content)
      setTotalCount(data.totalCount)
    }

    fetchData()
  }, [qs.page, tenant.id])
  return (
    <>
      <Toolbar className={classes.toolbar}>
        <CallToAction/>
      </Toolbar>

      <ResourceList
        name="users"
        items={users}
        location={location}
        totalCount={totalCount}
        empty={
          <FormattedMessage id="users.noUsersFound" defaultMessage="No users have been added yet.">{txt =>
            <EmptyResources message={txt}>
              <CallToAction/>
            </EmptyResources>}</FormattedMessage>
        }
      />
    </>
  )
}
