import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Formik} from 'formik'
import {Link, withRouter} from 'react-router-dom'
import * as Yup from 'yup'
import * as Api from 'lib/api'
import {
  Button,
  Paper,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

const UserSchema = Yup.object().shape({
  name: Yup.string().required('formValidations.required'),
  email: Yup.string()
    .required('formValidations.required')
    .email('formValidations.invalidEmail'),
})

async function handleSubmit(values) {
  var tenant = await Api.getTenant()
  return await Api.inviteUser(tenant.id, values)
}

export function InviteUserPage({history}) {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Formik
        validationSchema={UserSchema}
        onSubmit={values => {
          handleSubmit(values).then(user => {
            history.push('/users/' + user.id)
          })
        }}
        initialValues={{name: '', email: ''}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth className={classes.formControl}>
              <FormattedMessage id="name" htmlFor="name">
                {txt => <InputLabel htmlFor="name">{txt}</InputLabel>}
              </FormattedMessage>
              <Input
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                autoFocus
              />
              {errors.name && touched.name && (
                <FormattedMessage id={errors.name}>
                  {txt => <FormHelperText error={true}>{txt}</FormHelperText>}
                </FormattedMessage>
              )}
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <FormattedMessage id="email" htmlFor="email">
                {txt => <InputLabel htmlFor="email">{txt}</InputLabel>}
              </FormattedMessage>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && (
                <FormattedMessage id={errors.email}>
                  {txt => <FormHelperText error={true}>{txt}</FormHelperText>}
                </FormattedMessage>
              )}
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              <FormattedMessage id="inviteUser" />
            </Button>
            <Button
              component={Link}
              to="/users"
              variant="contained"
              className={classes.button}
            >
              <FormattedMessage id="buttons.cancel" />
            </Button>
          </form>
        )}
      </Formik>
    </Paper>
  )
}
export default withRouter(InviteUserPage)
