import React, {useState} from 'react'
import * as Api from 'lib/api'
import {always, cond, isEmpty, isNil, not, or, T} from 'ramda'
import {ProductDetail} from 'components/organisms/products'

const renderContent = cond([
  [({isLoading}) => isLoading, always(<h2>Loading...</h2>)],
  [({errorMessage}) => not(or(isNil(errorMessage), isEmpty(errorMessage))), always(<h2>Something
    went wrong!</h2>)],
  [T, ({product}) => <ProductDetail product={product} />],
])

export default function ProductDetailPage({match}) {

  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [product, setProduct] = useState(null)

  React.useEffect(() => {
    async function fetchData() {
      var result = await Api.getProductById(match.params.id)
      result.matchWith({
        Ok: ({value}) => {
          setProduct(value)
        },
        Error: ({value}) => {
          setErrorMessage(value.message)
        },
      })
      setIsLoading(false)
    }

    fetchData()
  }, [match.params.id])

  return renderContent({isLoading, errorMessage, product})
}
