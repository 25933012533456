import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Button, Paper} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import {Formik} from 'formik'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import * as Yup from 'yup'
import {prop, not, isEmpty, pickBy} from 'ramda'
import * as Api from 'lib/api'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3, 2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}))

const addProductSchema = Yup.object().shape({
  name: Yup.string().required().max(60),
  brand: Yup.string().max(30),
  quantity: Yup.number().typeError().integer().min(0).max(9999999),
})

export default function AddProductPage({history}) {
  const classes = useStyles()
  const {tenant} = React.useContext(AuthenticationContext)
  const [errorMessage, setErrorMessage] = React.useState(null)

  return <Paper className={classes.root}>
    <Formik validationSchema={addProductSchema}
            initialValues={{name: '', brand: '', quantity: ''}}
            onSubmit={async (values) => {
              const isNotEmpty = (val) => not(isEmpty(val))
              const result = await Api.createTenantProduct(tenant.id, pickBy(isNotEmpty, values))
              result.matchWith({
                Ok: ({value}) => {
                  history.push('/products/' + value.id)
                },
                Error: ({value}) => {
                  setErrorMessage(value.message)
                },
              })
            }}>
      {({handleSubmit, handleChange, handleBlur, errors, touched}) =>
        <form onSubmit={handleSubmit} noValidate>
          <Name autoFocus onChange={handleChange} onBlur={handleBlur}
                error={prop('name', errors)}
                touched={prop('name', touched)}/>
          <Brand onChange={handleChange} onBlur={handleBlur} error={prop('brand', errors)}
                 touched={prop('brand', touched)}/>
          <Quantity type="number" step={1} onChange={handleChange} onBlur={handleBlur}
                    error={prop('quantity', errors)}
                    touched={prop('quantity', touched)}/>
          {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
          <Toolbar disableGutters>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Add product
            </Button>
            <Button
              component={Link}
              to="/products"
              variant="contained"
              className={classes.button}
            >
              <FormattedMessage id="buttons.cancel"/>
            </Button>
          </Toolbar>
        </form>}
    </Formik>
  </Paper>
}

function Name({error, touched, ...props}) {
  return <TextField
    required
    id="name"
    label="Name"
    margin="normal"
    fullWidth
    helperText={error && touched && error}
    FormHelperTextProps={{error: true}}
    {...props}
  />
}

function Brand({error, touched, ...props}) {
  return <TextField
    id="brand"
    label="Brand"
    margin="normal"
    fullWidth
    helperText={error && touched && error}
    FormHelperTextProps={{error: true}}
    {...props}
  />
}

function Quantity({error, touched, ...props}) {
  return <TextField
    id="quantity"
    label="Quantity"
    margin="normal"
    fullWidth
    helperText={error && touched && error}
    FormHelperTextProps={{error: true}}
    {...props}
  />
}
