import {Hub, Logger} from 'aws-amplify'

Hub.listen('auth', data => {
  const logger = new Logger('auth hub handler')
  switch (data.payload.event) {
    case 'signIn':
      logger.info('user signed in') //[ERROR] My-Logger - user signed in
      break
    case 'signUp':
      logger.info('user signed up')
      break
    case 'signOut':
      logger.info('user signed out')
      break
    case 'signIn_failure':
      logger.warn('user sign in failed')
      break
    case 'configured':
      logger.info('the Auth module is configured')
      break
    default:
      logger.warn('unknown event ' + data.payload.event)
      break
  }
})
