import React from 'react'
import {Logger} from 'aws-amplify'
import {withRouter} from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import LoaderButton from 'components/atoms/LoaderButton'
import * as Api from 'lib/api'
import Auth from 'lib/auth'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'

const logger = new Logger('SignUpConfirmationForm')

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export const SignUpConfirmationForm = withRouter(
  ({email, password, history}) => {
    const classes = useStyles()

    const [isLoading, setIsLoading] = React.useState(false)

    const {signIn} = React.useContext(AuthenticationContext)

    const handleConfirmationSubmit = async event => {
      event.preventDefault()
      const {confirmationCode} = event.target.elements
      setIsLoading(true)
      try {
        await Auth.confirmSignUp(email, confirmationCode.value)
        await Auth.signIn(email, password)
        await Api.createUser({email})
        signIn()
      } catch (e) {
        logger.error(e)
        setIsLoading(false)
      }
    }

    return (
      <form onSubmit={handleConfirmationSubmit} className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="confirmation-code"
          label="Confirmation Code"
          name="confirmationCode"
          helperText="Please check your email for the code."
          autoFocus
        />
        <LoaderButton
          type="submit"
          isLoading={isLoading}
          loadingText="Verifying…"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Verify
        </LoaderButton>
      </form>
    )
  },
)
