import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Routes from './Routes'
import AuthenticationProvider from 'components/providers/AuthenticationProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import {createMuiTheme} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/styles'
import blue from '@material-ui/core/colors/blue'

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'capitalize',
    },
  },
})

class App extends React.Component {
  render() {
    return (
      <>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
          <Router>
            <AuthenticationProvider>
              <Routes/>
            </AuthenticationProvider>
          </Router>
        </ThemeProvider>
      </>
    )
  }
}

export default App
