import React from 'react'
import {FormattedMessage} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import LoaderButton from 'components/atoms/LoaderButton'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import {Link as RouterLink, withRouter} from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Layout from 'components/layouts/WelcomePageLayout'
import Auth, {NewPasswordRequired} from 'lib/auth'
import * as Api from 'lib/api'
import {is} from 'ramda'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {FormHelperText} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function LoginForm() {
  const classes = useStyles()

  const [isLoading, setIsLoading] = React.useState(false)

  const [errorMessage, setErrorMessage] = React.useState(null)

  const {signIn, setUser} = React.useContext(AuthenticationContext)

  const handleSubmit = async event => {
    event.preventDefault()

    setIsLoading(true)

    const {email, password} = event.target.elements
    const result = await Auth.signIn(email.value, password.value)
    result.matchWith({
      Ok: async ({value}) => {
        const tenant = await Api.getTenant()
        Auth.switchTenant(value, tenant)
        signIn()
      },
      Error: ({value}) => {
        if (is(NewPasswordRequired, value)) {
          setUser(value.user)
        } else {
          setErrorMessage(value.message)
          setIsLoading(false)
        }
      },
    })
  }

  return (
    <Layout header={Header}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Email />
        <Password />
        {errorMessage && (
          <FormattedMessage id={`login.errors.${errorMessage}`}>
            {txt => <FormHelperText error={true}>{txt}</FormHelperText>}
          </FormattedMessage>
        )}
        <LoaderButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loadingText="Logging in..."
          isLoading={isLoading}
        >
          Login
        </LoaderButton>
      </form>
      <Grid container>
        <Grid item xs>
          <Link to="#/forgot-password" variant="body2" component={RouterLink}>
            <FormattedMessage id="login.forgotPasswordLink" />
          </Link>
        </Grid>
        <Grid item>
          <Link to="/signup" variant="body2" component={RouterLink}>
            <FormattedMessage id="login.signUpLink" />
          </Link>
        </Grid>
      </Grid>
    </Layout>
  )
}

const Header = () => (
  <Typography variant="h5" component="h1">
    <FormattedMessage id="login.greeting" />
  </Typography>
)

function Email() {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      autoFocus
    />
  )
}

function Password() {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="password"
      autoComplete="current-password"
    />
  )
}

export default withRouter(LoginForm)
