import {
  head,
  curry,
  isEmpty,
  prop,
  ifElse,
  always,
} from 'ramda'
import {Logger} from 'aws-amplify'
import Result from 'folktale/result'
import {transformation} from './users'

const baseUrl = process.env.REACT_APP_API_URL
const tenantsUrl = baseUrl + '/tenants'

const logger = new Logger('tenants-api')

// getTenant :: (String, Number) -> Promise(Tenant)
export const getTenant = getAll => async () => {
  var tenants = await getAll(tenantsUrl, 1)
  const content = prop('content', tenants)
  return ifElse(isEmpty, always(null), head)(content)
}

// createTenant :: (String, Tenant) -> Promise(Tenant)
export const createTenant = curry((post, tenant) => post(tenantsUrl, tenant))

// inviteUser :: (String -> Promise(Object)) -> Number -> User -> Promise(Object)
export const inviteUser = curry(async function(post, tenantId, user) {
  return await post(tenantsUrl + '/' + tenantId + '/users/_invite', user)
})

// getTenantUsers :: (String, Number -> Promise(Response) -> Number -> Number)
export const getTenantUsers = curry(async function(getAll, tenantId, page) {
  return await getAll(tenantsUrl + '/' + tenantId + '/users', page).then(
    response => ({
      ...response,
      content: response.content.map(transformation),
    }),
  )
})

export const getTenantProducts = curry(async function(getAll, tenantId, page) {
  try {
    var response = await getAll(tenantsUrl + '/' + tenantId + '/products', page)
    return Result.Ok(response)
  } catch(err) {
    logger.error(err)
    return Result.Error(new NotFound('Something went wrong'))
  }
})

export const createTenantProduct = curry(async function(post, tenantId, product) {
  try {
    var response = await post(tenantsUrl + '/' + tenantId + '/products', product)
    return Result.Ok(response)
  } catch(err) {
    logger.error(err)
    return Result.Error(new MethodNotAllowed('Something went wrong'))
  }
})

class NotFound extends Error {}

class MethodNotAllowed extends Error {}
