import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import {IntlProvider} from 'react-intl'
import App from './App'
import * as serviceWorker from './serviceWorker'
import messages from './messages'
import flattenMessages from './utils/flattenMessages'
import 'lib/pubsub'
import 'typeface-roboto'

const locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-GB'

const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
  Analytics: {
    disabled: true,
  },
}

Amplify.configure(awsConfig)
Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL

ReactDOM.render(
  <IntlProvider
    locale={locale}
    messages={Object.assign(
      {},
      flattenMessages(messages['en-GB']),
      flattenMessages(messages[locale] || {}),
    )}
  >
    <App />
  </IntlProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
