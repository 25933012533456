import React from 'react'
import queryString from 'query-string'
import {Link} from 'react-router-dom'
import Avatar from 'react-avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Card from '@material-ui/core/Card'
import MuiAvatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import {Pagination} from './Pagination'

export function ResourceList({name, items, totalCount, location, empty}) {
  const qs = queryString.parse(location.search)

  if (items.length === 0 && empty) {
    return empty
  }

  return (
    <List component={Card}>
      {items.map(item => (
        <React.Fragment key={item.id}>
          <ListItem>
            <ListItemAvatar>
              <MuiAvatar component={Avatar} size={48} {...{name: item.name}} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link to={`/${name.toLowerCase()}/${item.id}`}>
                  <Typography variant="h6">{item.name}</Typography>
                </Link>
              }
            />
          </ListItem>
          <Divider/>
        </React.Fragment>
      ))}
      <Pagination
        page={qs.page}
        pageSize={process.env.REACT_APP_PAGE_SIZE}
        totalCount={totalCount}
        location={location}
      />
    </List>
  )
}
