import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ChangePasswordDialog} from 'components/organisms/account'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))

export default function AccountSettingsPage() {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(null)

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h1">Account Settings</Typography>

      <List>
        <ListItem button onClick={() => void setOpenDialog('changePassword')}>
          <ListItemAvatar>
            <Avatar>
              <FontAwesomeIcon icon={faLock} size="sm"/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Change password"
            secondary="********"
          />
        </ListItem>
        <ChangePasswordDialog open={openDialog === 'changePassword'}
                              onClose={() => setOpenDialog(null)}/>
      </List>
    </div>
  )
}
