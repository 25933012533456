import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Auth} from 'aws-amplify'
import Typography from '@material-ui/core/Typography'
import Layout from 'components/layouts/WelcomePageLayout'
import {SignUpForm, SignUpConfirmationForm} from 'components/organisms/signup'

class SignUpPage extends React.Component {
  state = {
    email: '',
    password: '',
    newUser: null,
  }

  handleSubmit = async ({email, password}) => {
    this.setState({email, password})
    try {
      const newUser = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })
      this.setState({
        newUser,
      })
    } catch (e) {
      // https://serverless-stack.com/chapters/signup-with-aws-cognito.html
      // 1. Check for the UsernameExistsException in the handleSubmit method’s catch block.
      // 2. Use the Auth.resendSignUp() method to resend the code if the user has not been previously confirmed.
      //    Here is a link to the Amplify API docs.
      // 3. Confirm the code just as we did before.
      alert(e.message)
    }
  }

  render() {
    return (
      <Layout header={Header}>
        {this.state.newUser === null ? (
          <SignUpForm onSubmit={this.handleSubmit} />
        ) : (
          <SignUpConfirmationForm
            email={this.state.email}
            password={this.state.password}
          />
        )}
      </Layout>
    )
  }
}

const Header = ({className}) => (
  <Typography variant="h5" component="h1" className={className}>
    <FormattedMessage id="signUp.greeting" />
  </Typography>
)

export default SignUpPage
