import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import {
  faUsersCog,
  faHome,
  faBoxOpen
} from '@fortawesome/free-solid-svg-icons'
import Container from '@material-ui/core/Container'
import AppBarNavLink from '../molecules/AppBarNavLink'
import AppBarProfileNavItem from '../molecules/AppBarProfileNavItem'

const useStyles = makeStyles(() => ({
  left: {
    flexGrow: 1,
  },
}))

export const AppBar = () => {
  const authContext = React.useContext(AuthenticationContext)
  const classes = useStyles()

  return (
    <MuiAppBar position="static" color="default">
      <Container maxWidth="lg" component={Toolbar}>
        <div className={classes.left}>
          <AppBarNavLink
            to="/"
            exact
            text="Home"
            icon={faHome}
          />
          <AppBarNavLink
            to="/products"
            text="Products"
            icon={faBoxOpen}
          />
        </div>
        <AppBarNavLink
          to="/users"
          text="Users"
          icon={faUsersCog}
        />
        {authContext.isAuthenticated && <AppBarProfileNavItem />}
      </Container>
    </MuiAppBar>
  )
}
