import axios from 'axios'
import {Auth} from 'aws-amplify'

export async function getAll(url, page = 1) {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken().getJwtToken()
  const response = await axios.get(url, {
    params: {_page: page, _limit: process.env.REACT_APP_PAGE_SIZE},
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  })
  return {content: response.data, totalCount: Number(response.headers['x-total-count'])}
}

export async function post(url, body) {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken().getJwtToken()
  const response = await axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  })
  return response.data
}

export async function getById(url, id) {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken().getJwtToken()
  const response = await axios.get(url + '/' + id, {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  })
  return response.data
}

export async function deleteById(url, id) {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken().getJwtToken()
  return await axios.delete(url + '/' + id, {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  })
}

export async function putById(url, id, body) {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken().getJwtToken()
  return await axios.put(url + '/' + id, body, {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  })
}
