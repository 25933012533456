import React from 'react'
import {withRouter} from 'react-router-dom'
import {LoginForm, CompleteNewPasswordForm, ForgotPasswordForm} from 'components/organisms/login'
import Auth from 'lib/auth'
import {AuthenticationContext} from '../providers/AuthenticationProvider'

function LoginPage({location}) {
  const {user} = React.useContext(AuthenticationContext)

  if (location.hash === "#/forgot-password") {
    return <ForgotPasswordForm />
  }
  return Auth.newPasswordRequired(user) ? (
    <CompleteNewPasswordForm />
  ) : (
    <LoginForm />
  )
}

export default withRouter(LoginPage)
