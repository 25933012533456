import Result from 'folktale/result'
import {curry} from 'ramda'
import {Logger} from 'aws-amplify'

const logger = new Logger('products-api')

const baseUrl = process.env.REACT_APP_API_URL
const productsUrl = baseUrl + '/products'

export const getProductById = curry(async (getById, id) => {
  try {
    const response = await getById(productsUrl, id)
    return Result.Ok(response)
  } catch(err) {
    logger.error(err)
    return Result.Error(new Error('something went wrong'))
  }
})

export const updateProduct = curry(async (putById, id, product) => {
  try {
    const response = await putById(productsUrl, id, product)
    return Result.Ok(response.data)
  } catch(err) {
    logger.err(err)
    return Result.Error(new Error('something went wrong'))
  }
})
