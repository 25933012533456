import {post, getAll, getById, putById} from './common'
import * as tenants from './tenants'
import * as products from './products'

export * from './patients'
export * from './employees'

export const inviteUser = tenants.inviteUser(post)
export const getTenantUsers = tenants.getTenantUsers(getAll)
export const createTenant = tenants.createTenant(post)
export const getTenant = tenants.getTenant(getAll)
export const getTenantProducts = tenants.getTenantProducts(getAll)
export const createTenantProduct = tenants.createTenantProduct(post)

export const getProductById = products.getProductById(getById)
export const updateProduct = products.updateProduct(putById)

export * from './users'
