import {head, propOr, splitAt} from 'ramda'
import {post, getById} from './common'

const baseUrl = process.env.REACT_APP_API_URL
const usersUrl = baseUrl + '/users'

const displayNameFromEmail = user =>
  head(splitAt(user.email.lastIndexOf('@'), user.email))

const displayName = user => propOr(displayNameFromEmail(user), 'name', user)

export function transformation(user) {
  return {...user, name: displayName(user)}
}

export async function createUser(user) {
  return await post(usersUrl, user)
}

export async function getUserById(id) {
  var user = await getById(usersUrl, id)
  return transformation(user)
}
