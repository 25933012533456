import React from 'react'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/styles'

const useStylesStatistics = makeStyles(theme =>({
  root: {
    display: 'inline-flex', flexDirection: 'column',
    '& + &': {
      marginLeft: theme.spacing(5)
    }
  },
}))

export function Statistic({children}) {
  const classes = useStylesStatistics()
  return <div className={classes.root}>{children}</div>
}

export function StatisticLabel({children}) {
  return (<Box fontWeight="fontWeightBold">
    {children}
  </Box>)
}

export function StatisticValue({children}) {
  return (<Box>
    {children}
  </Box>)
}
