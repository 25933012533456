import * as Yup from 'yup'

export const passwordSchema = Yup.string()
  .required('formValidations.required')
  .min(
    Number(process.env.REACT_APP_PASSWORD_MIN_LENGTH),
    'formValidations.passwordTooShort',
  )
  .matches(/.*[0-9].*/, 'formValidations.atLeastOneNumber')
  .matches(/.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`].*/, 'formValidations.atLeastOneSpecialChar')
  .matches(/.*[a-z].*/, 'formValidations.atLeastOneLowercase')
  .matches(/.*[A-Z].*/, 'formValidations.atLeastOneUppercase')
