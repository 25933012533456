import React from 'react'

export default function DefinitionItem({term, definition}) {
  const dtStyle = {
    float: 'left',
    width: '156px',
    overflow: 'hidden',
    clear: 'left',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    color: '#5d7079'
  }
  const ddStyle = {
    marginBottom: '16px',
    marginLeft: '180px',
    fontSize: '16px'
  }
  return <div>
    <dt style={dtStyle}>{term}</dt>
    <dd style={ddStyle}>{definition || '-'}</dd>
  </div>
}
