import React, {useContext} from 'react'
import {cond, always, T, isNil, or, isEmpty, not, equals} from 'ramda'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import * as Api from 'lib/api'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import {ProductList} from 'components/organisms/products'
import {Link} from 'react-router-dom'

const renderContent = cond([
  [({isLoading}) => isLoading, always(<h2>Loading...</h2>)],
  [({errorMessage}) => not(or(isNil(errorMessage), isEmpty(errorMessage))), always(<h2>Something went wrong!</h2>)],
  [({totalCount}) => equals(0, totalCount), always(<h2>No products found</h2>)],
  [T, ({products}) => <ProductList products={products}/>],
])

const useStyles = makeStyles(theme => ({
  actionsBar: {
    padding: theme.spacing(2, 0),
  },
}))

export default function ProductsPage() {
  const classes = useStyles()
  const {tenant} = useContext(AuthenticationContext)
  const [isLoading, setIsLoading] = React.useState(true)
  const [totalCount, setTotalCount] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [products, setProducts] = React.useState([])

  React.useEffect(() => {
    async function fetchData() {
      var response = await Api.getTenantProducts(tenant.id, 1)
      response.matchWith({
        Ok: ({value}) => {
          setProducts(value.content)
          setTotalCount(value.totalCount)
        },
        Error: ({value}) => {
          setErrorMessage(value.message)
        },
      })
      setIsLoading(false)
    }

    fetchData()
  }, [tenant.id])

  return <>
    <Grid container justify="space-between" className={classes.actionsBar}>
      <Grid item><Typography variant="h6">Products</Typography></Grid>
      <Grid item><Button variant="contained" size="small" color="primary" component={Link}
                         to="/products/add">Add Product</Button></Grid>
    </Grid>
    {renderContent({isLoading, totalCount, errorMessage, products})}
  </>
}
