import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {AuthenticationConsumer} from 'components/providers/AuthenticationProvider'
import AppLayout from 'components/layouts/AppLayout'
import * as Api from 'lib/api'

export default ({component: C, ...rest}) => (
  <AuthenticationConsumer>
    {({isAuthenticated, hasTenant}) => (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticated && hasTenant) {
            if (props.match.path === '/setup') {
              return <Redirect to={'/'} />
            }
          }
          if (isAuthenticated && (hasTenant || props.match.path === '/setup')) {
            return (
              <AppLayout fullscreen={props.match.path === '/setup'}>
                <C {...props} api={Api} />
              </AppLayout>
            )
          }
          if (isAuthenticated) {
            return <Redirect to={'/setup'} />
          }
          return (
            <Redirect
              to={`/login?redirect=${props.location.pathname}${props.location.search}`}
            />
          )
        }}
      />
    )}
  </AuthenticationConsumer>
)
