import React from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup'
import Auth from 'lib/auth'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import {Box, Typography, Button, Toolbar, Container} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
})

export default function SetupPage({api}) {
  const {setTenant} = React.useContext(AuthenticationContext)

  return (
    <Box component="div" mt={5} pt={5}>
      <Container maxWidth="sm">
        <Typography variant="h5" component="h1">Setup</Typography>
        <Formik
          initialValues={{name: '', country: ''}}
          validationSchema={validationSchema}
          onSubmit={async ({name, country}) => {
            var tenant = await api.createTenant({name, country})
            var user = await Auth.currentAuthenticatedUser()
            Auth.switchTenant(user, tenant)
            setTenant(tenant)
          }}
        >
          {({handleSubmit, handleChange, handleBlur, errors, touched, values}) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  name="name"
                  label="Tenant name"
                  fullWidth required autoFocus value={values['name']}
                  margin="normal" onChange={handleChange} onBlur={handleBlur}
                  helperText={errors['name'] && touched['name'] && errors['name']}
                  FormHelperTextProps={{error: true}}
                />
                <TextField
                  id="country"
                  name="country"
                  select fullWidth required
                  label="Country" value={values['country']}
                  margin="normal" onChange={handleChange} onBlur={handleBlur}
                  helperText={errors['country'] && touched['country'] && errors['country']}
                  FormHelperTextProps={{error: true}}
                >
                  <MenuItem value="">Select...</MenuItem>
                  <MenuItem value="IN">India</MenuItem>
                  <MenuItem value="GB">United Kingdom</MenuItem>
                </TextField>
                <Toolbar disableGutters>
                  <Button type="submit" variant="outlined" size="large" color="primary">
                    Submit
                  </Button>
                </Toolbar>
              </form>
            )
          }}
        </Formik>
      </Container>
    </Box>
  )
}
