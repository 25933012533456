import React from 'react'
import {makeStyles} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import {Link} from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import DefinitionListItem from 'components/atoms/DefinitionListItem'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  content: {
    paddingTop: theme.spacing(0.5),
  },
}))

export default function ProductCard({product}) {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} to={'/products/' + product.id}>
        <CardHeader title={product.name} subheader={product.brand}/>
      </CardActionArea>
      <CardContent className={classes.content}>
        <List component="dl">
          <DefinitionListItem term="Quantity" definition={product.quantity}/>
        </List>
      </CardContent>
    </Card>
  )
}
