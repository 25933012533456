import {isNil, not, propEq} from 'ramda'
import {Logger, Auth} from 'aws-amplify'
import Result from 'folktale/result'
import * as Api from 'lib/api'

const logger = new Logger('Auth')

export const newPasswordRequired = propEq('challengeName', 'NEW_PASSWORD_REQUIRED')

export class NewPasswordRequired {
  constructor(user) {
    this.user = user
  }
}

export class UserNotConfirmed extends Error {
}

export class PasswordResetRequired extends Error {
}

export class NotAuthorized extends Error {
}

export class UserNotFound extends Error {
}

export class UnknownFailure extends Error {
}

export class CodeMismatch extends Error {
}

export class ExpiredCode extends Error {
}

export const signIn = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password)
    if (newPasswordRequired(user)) {
      return Result.Error(new NewPasswordRequired(user))
    } else {
      return Result.Ok(user)
    }
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      return Result.Error(new UserNotConfirmed(err.code))
    } else if (err.code === 'PasswordResetRequiredException') {
      return Result.Error(new PasswordResetRequired(err.code))
    } else if (err.code === 'NotAuthorizedException') {
      return Result.Error(new NotAuthorized(err.code))
    } else if (err.code === 'UserNotFoundException') {
      return Result.Error(new UserNotFound(err.code))
    }
    logger.info(err)
    return Result.Error(new UnknownFailure(err))
  }
}

export const forgotPassword = async (username) => {
  try {
    const response = await Auth.forgotPassword(username)
    logger.info(response)
    return Result.Ok(response)
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      return Result.Error(new UserNotFound(err.code))
    }
    logger.info(err)
    return Result.Error(new UnknownFailure(err))
  }
}

export const forgotPasswordSubmit = async (username, code, newPassword) => {
  try {
    const response = await Auth.forgotPasswordSubmit(username, code, newPassword)
    logger.info(response)
    return Result.Ok(response)
  } catch (err) {
    if (err.code === 'CodeMismatchException') {
      return Result.Error(new CodeMismatch(err.code))
    }
    if (err.code === 'ExpiredCodeException') {
      return Result.Error(new ExpiredCode(err.code))
    }
    logger.info(err)
    return Result.Error(new UnknownFailure(err))
  }
}

export async function changePassword(user, oldPassword, newPassword) {
  try {
    const response = await Auth.changePassword(user, oldPassword, newPassword)
    logger.info(response)
    return Result.Ok(response)
  } catch(err) {
    return Result.Error(new NotAuthorized('NotAuthorizedException'))
  }
}

export const signOut = async () => {
  const user = await currentAuthenticatedUser()
  removeTenantFromStorage(user)
  await Auth.signOut()
}

export const currentAuthenticatedUser = async () => {
  return await Auth.currentAuthenticatedUser()
}

export const confirmSignUp = async (username, code) => {
  return await Auth.confirmSignUp(username, code)
}

export const currentTenant = (user) => {
  const namespace = `${process.env.REACT_APP_CLIENT_ID}.${user.username}.tenant`
  const tenant = localStorage.getItem(namespace)
  return JSON.parse(tenant)
}

export const switchTenant = (user, tenant) => {
  if (not(isNil(tenant))) {
    saveTenantToLocalStorage(user, tenant)
  } else {
    logger.info('didn\'t find any tenant...')
  }
}

function saveTenantToLocalStorage(user, tenant) {
  const namespace = `${process.env.REACT_APP_CLIENT_ID}.${user.username}.tenant`
  logger.info(`saving tenant to local storage '${namespace}'`)
  localStorage.setItem(namespace, JSON.stringify({id: tenant.id}))
}

function removeTenantFromStorage(user) {
  const namespace = `${process.env.REACT_APP_CLIENT_ID}.${user.username}.tenant`
  logger.info(`removing tenant to local storage '${namespace}'`)
  localStorage.removeItem(namespace)
}

async function completeNewPassword(user, newPassword) {
  const signedInUser = await Auth.completeNewPassword(user, newPassword)
  const tenant = await Api.getTenant()
  switchTenant(signedInUser, tenant)
  return signedInUser
}

export default {
  newPasswordRequired,
  signIn,
  confirmSignUp,
  completeNewPassword,
  currentAuthenticatedUser,
  currentTenant,
  switchTenant,
  signOut,
  forgotPassword,
  forgotPasswordSubmit,
  changePassword
}
