import {FormHelperText, makeStyles} from '@material-ui/core'
import {Formik} from 'formik'
import LoaderButton from '../../atoms/LoaderButton'
import {FormattedMessage, injectIntl} from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import {Link as RouterLink} from 'react-router-dom'
import React from 'react'
import * as Yup from 'yup'
import TextField from '@material-ui/core/TextField'
import {passwordSchema} from 'lib/schemas'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const ForgotPasswordConfirmationSchema = Yup.object().shape({
  confirmationCode: Yup.string().required('formValidations.required'),
  newPassword: passwordSchema,
})

export default function ForgotPasswordConfirmationForm({username, handleSubmit}) {
  const classes = useStyles()
  return <Formik
    validationSchema={ForgotPasswordConfirmationSchema}
    initialValues={{username, confirmationCode: '', newPassword: ''}}
    onSubmit={handleSubmit}
  >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <input value={values.username} name='username' hidden readOnly autoComplete="username"/>
        <ConfirmationCode values={values} errors={errors} touched={touched} handleChange={handleChange}
                          handleBlur={handleBlur}/>
        <Password values={values} errors={errors} touched={touched} handleChange={handleChange}
                  handleBlur={handleBlur}/>
        <LoaderButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loadingText="Submitting..."
          isLoading={false}
        >
          <FormattedMessage id="forgotPassword.confirmCode" defaultMessage='Confirm and reset'/>
        </LoaderButton>
        <Grid container>
          <Grid item xs>
            <Link to="/login" variant="body2" component={RouterLink}>
              <FormattedMessage id="forgotPassword.backToLogin" defaultMessage="Back to login"/>
            </Link>
          </Grid>
        </Grid>
      </form>
    )}</Formik>
}

const ConfirmationCode = injectIntl(({values, errors, touched, handleChange, handleBlur, intl}) => {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="confirmation-code"
        label="Confirmation Code"
        name="confirmationCode"
        autoFocus
        value={values.confirmationCode}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.confirmationCode && touched.confirmationCode && (
        <FormHelperText error>
          {intl.formatMessage({id: errors.confirmationCode})}
        </FormHelperText>
      )}
    </>)
})

const Password = injectIntl(({values, errors, touched, handleChange, handleBlur, intl}) => {
  return (
    <>
      <TextField
        type='password'
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="new-password"
        label="New Password"
        name="newPassword"
        autoComplete="new-password"
        value={values.newPassword}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.newPassword && touched.newPassword && (
        <FormHelperText error>
          {intl.formatMessage({id: errors.newPassword})}
        </FormHelperText>
      )}
    </>)
})
