import React from 'react'
import {Logger} from 'aws-amplify'
import Auth from 'lib/auth'
import {isNil, not} from 'ramda'

const logger = new Logger('AuthenticationProvider')

export const AuthenticationContext = React.createContext()

export const AuthenticationConsumer = AuthenticationContext.Consumer

function AuthenticationProvider({children}) {
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [tenant, setTenant] = React.useState({})

  React.useEffect(() => {
    async function authenticateUser() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        const tenant = Auth.currentTenant(currentUser)
        setTenant(tenant)
        setUser(currentUser)
        setIsAuthenticated(true)
      } catch (e) {
        logger.error(e)
      } finally {
        setIsAuthenticating(false)
      }
    }
    authenticateUser()
  }, [])

  const signIn = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      logger.debug('currentUser', currentUser)
      const tenant = Auth.currentTenant(currentUser)
      logger.debug('tenant', tenant)
      setTenant(tenant)
      setUser(currentUser)
      setIsAuthenticated(true)
    } catch (e) {
      logger.error(e)
    }
  }

  const signOut = () => {
    setUser({})
    setTenant({})
    setIsAuthenticated(false)
  }

  const contextValue = {
    isAuthenticated,
    hasTenant: tenant && not(isNil(tenant.id)),
    user,
    signIn,
    signOut,
    setUser,
    setTenant,
    tenant
  }

  return (
    // value prop is where we define what values
    // that are accessible to consumer components
    <AuthenticationContext.Provider value={contextValue}>
      {!isAuthenticating && children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
