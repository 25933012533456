import React from 'react'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {Toolbar} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end'
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export function Pagination({page = 1, pageSize, totalCount, location, className}) {
  page = Number(page)
  const hasPrevious = page > 1
  const hasNext = totalCount > page * pageSize
  const previous = `${location.pathname}?page=${page - 1}`
  const next = `${location.pathname}?page=${page + 1}`

  const classes = useStyles()

  return (
    <Toolbar component={'nav'} disableGutters className={classes.root}>
      {hasPrevious ? (
        <Button to={previous} component={Link} variant='contained' className={classes.button} title='Previous'>
          Previous
        </Button>
      ) : (
        <Button variant='contained' className={classes.button}
                tabIndex="-1"
                aria-disabled={true}
                disabled
        >
          Previous
        </Button>
      )}

      {hasNext ? (
        <Button to={next} component={Link} variant='contained' className={classes.button} title='Next'>
          Next
        </Button>
      ) : (
        <Button
          tabIndex="-1"
          aria-disabled={true}
          disabled variant='contained' className={classes.button}
        >
          Next
        </Button>
      )}
    </Toolbar>
  )
}
