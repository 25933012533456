import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import * as Yup from 'yup'
import {FormattedMessage, useIntl} from 'react-intl'
import {Formik} from 'formik'
import {passwordSchema} from 'lib/schemas'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import LoaderButton from 'components/atoms/LoaderButton'
import Auth from 'lib/auth'
import {FormHelperText} from '@material-ui/core'

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('formValidations.required'),
  newPassword: passwordSchema,
})

export default function ChangePasswordDialog({open, onClose}) {
  const intl = useIntl()
  const {user} = React.useContext(AuthenticationContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const currentPwdLbl = intl.formatMessage({
    id: 'currentPassword',
  })
  const newPwdLbl = intl.formatMessage({
    id: 'newPassword',
  })

  async function submitForm(values) {
    setErrorMessage(null)
    setIsLoading(true)
    const result = await Auth.changePassword(user, values.currentPassword, values.newPassword)
    result.matchWith({
      Ok: () => {
        onClose()
      },
      Error: ({value}) => {
        setErrorMessage(value.message)
      },
    })
    setIsLoading(false)
  }

  return <Dialog open={open} onClose={onClose}>
    <Formik validationSchema={ChangePasswordSchema}
            initialValues={{currentPassword: '', newPassword: ''}}
            onSubmit={submitForm}>{({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          {errorMessage && (
            <FormattedMessage id={`changePassword.errors.${errorMessage}`}>
              {txt => <FormHelperText error={true}>{txt}</FormHelperText>}
            </FormattedMessage>
          )}
          <Password id="current-password"
                    name="currentPassword"
                    label={currentPwdLbl}
                    value={values.currentPassword}
                    error={errors.currentPassword && intl.formatMessage({id: errors.currentPassword})}
                    touched={touched.currentPassword}
                    handleChange={handleChange}
                    handleBlur={handleBlur}/>
          <Password id="new-password"
                    name="newPassword"
                    label={newPwdLbl}
                    value={values.newPassword}
                    error={errors.newPassword && intl.formatMessage({id: errors.newPassword})}
                    touched={touched.newPassword}
                    handleChange={handleChange}
                    handleBlur={handleBlur}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <LoaderButton type="submit" color="primary" isLoading={isLoading} loadingText="Saving...">
            Save
          </LoaderButton>
        </DialogActions>
      </form>
    )}</Formik>
  </Dialog>
}

const Password = ({id, name, label, handleChange, handleBlur, value, error, touched}) => <TextField
  type="password"
  margin="normal"
  fullWidth
  required
  id={id}
  name={name}
  label={label}
  value={value}
  autoComplete="current-password"
  onChange={handleChange}
  onBlur={handleBlur}
  helperText={error && touched && error}
  FormHelperTextProps={{error: true}}
/>
