import React from 'react'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginRight: theme.spacing(1),
  },
}))

export default function({isLoading, loadingText, children, ...props}) {
  const classes = useStyles()
  return (
    <Button disabled={isLoading} {...props}>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {isLoading ? loadingText : children}
    </Button>
  )
}
