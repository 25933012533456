import React from 'react'
import {makeStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {NavLink} from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  root: {
    height: '64px',
    borderRadius: 0,
    borderBottom: theme.spacing(.5) + 'px solid transparent',
    '&.active': {
      borderBottomColor: theme.palette.secondary.light
    }
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const NavLinkRef = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />)

export default function AppBarNavLink({icon, text, ...rest}) {
  const classes = useStyles()
  return (
    <Button
      classes={{root: classes.root, label: classes.label}} {...rest} component={NavLinkRef}
    >
      <FontAwesomeIcon icon={icon} size="2x" className={classes.icon}/>
      <span>{text}</span>
    </Button>
  )
}
