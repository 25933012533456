import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ProductCard from './ProductCard'

export default function ProductList({products}) {
  const theme = useTheme()
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const gridListCols = matchesMdUp ? 3 : matchesSmUp ? 2 : 1

  return <GridList cols={gridListCols} cellHeight='auto' style={{margin: '-6px'}}>
    {products.map(product =>
      <GridListTile cols={1} key={product.id}>
        <ProductCard product={product}/>
      </GridListTile>)}
  </GridList>
}
