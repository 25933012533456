import React from 'react'
import {Route} from 'react-router-dom'
import Container from '@material-ui/core/Container';
import * as Api from 'lib/api'
import {AppBar} from './AppBar'

const AppLayout = ({children, className, fullscreen}) => {
  // const [isSiderbarOpen, setIsSidebarOpen] = React.useState(false)
  return (
    <div className={className}>
      {/* {!fullscreen && (
        <SideBar
          open={isSiderbarOpen}
          closeSidebar={() => setIsSidebarOpen(false)}
        />
      )} */}
      <main role="main">
        {!fullscreen && (
          <AppBar />
        )}
        <Container maxWidth="lg">{children}</Container>
      </main>
    </div>
  )
}

export const DefaultLayout = ({
  component: Component,
  header,
  actionsBar,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout header={header} actionsBar={actionsBar}>
          <Component {...matchProps} api={Api} />
        </AppLayout>
      )}
    />
  )
}

export default AppLayout
