import React from 'react'
import {Switch} from 'react-router-dom'
import DashboardPage from 'components/pages/DashboardPage'
import LoginPage from 'components/pages/LoginPage'
import {NotFoundPage} from 'components/pages/NotFoundPage'
import SignUpPage from 'components/pages/SignUpPage'
import SetupPage from 'components/pages/SetupPage'
import {UsersPage, InviteUserPage, UserDetailPage} from 'components/pages/users'
import UnauthenticatedRoute from 'components/UnauthenticatedRoute'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import {AccountSettingsPage} from 'components/pages/account'
import {ProductsPage, AddProductPage, ProductDetailPage, EditProductPage} from 'components/pages/products'

export default () => (
  <Switch>
    <UnauthenticatedRoute path="/login" exact component={LoginPage} />
    <UnauthenticatedRoute path="/signup" exact component={SignUpPage} />
    <AuthenticatedRoute path="/setup" component={SetupPage} />
    <AuthenticatedRoute exact path="/" component={DashboardPage} />
    <AuthenticatedRoute exact path="/users" component={UsersPage} />
    <AuthenticatedRoute exact path="/users/invite" component={InviteUserPage} />
    <AuthenticatedRoute exact path="/users/:id" component={UserDetailPage} />

    <AuthenticatedRoute exact path="/me/settings" component={AccountSettingsPage} />

    <AuthenticatedRoute exact path="/products" component={ProductsPage} />
    <AuthenticatedRoute path="/products/add" component={AddProductPage} />
    <AuthenticatedRoute path="/products/:id/edit" component={EditProductPage} />
    <AuthenticatedRoute path="/products/:id" component={ProductDetailPage} />
    {/* Finally, catch all unmatched routes */}
    <AuthenticatedRoute component={NotFoundPage} />} />
  </Switch>
)
