import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {AuthenticationConsumer} from 'components/providers/AuthenticationProvider'

export default ({component: C, ...rest}) => (
  <AuthenticationConsumer>
    {({isAuthenticated}) => (
      <Route
        {...rest}
        render={props =>
          !isAuthenticated ? <C {...props} /> : <Redirect to="/" />
        }
      />
    )}
  </AuthenticationConsumer>
)
