import React from 'react'
import {FormattedMessage} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Layout from '../../layouts/WelcomePageLayout'
import ForgotPasswordForm from '../../organisms/login/ForgotPasswordForm'
import Auth from 'lib/auth'
import ForgotPasswordConfirmationForm from '../../organisms/login/ForgotPasswordConfirmationForm'
import {FormHelperText, Link} from '@material-ui/core'
import {Link as RouterLink} from 'react-router-dom'

const Header = ({className}) => (
  <Typography variant="h5" component="h1" className={className}>
    <FormattedMessage id="forgotPassword.text"/>
  </Typography>
)

export default function ForgotPasswordPage() {
  const [view, setView] = React.useState('enterEmail')
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [username, setUsername] = React.useState(null)

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const result = await Auth.forgotPassword(values.username)
    result.matchWith({
      Ok: async () => {
        setUsername(values.username)
        setView('confirmCode')
      },
      Error: ({value}) => {
        setErrorMessage(value.message)
      },
    })
    setIsLoading(false)
  }

  const handleConfirmation = async (values) => {
    setIsLoading(true)
    const result = await Auth.forgotPasswordSubmit(values.username, values.confirmationCode, values.newPassword)
    result.matchWith({
      Ok: async () => {
        setView('success')
      },
      Error: ({value}) => {
        setErrorMessage(value.message)
      },
    })
    setIsLoading(false)
  }

  if (view === 'enterEmail') {
    return <Layout header={Header}>
      <Typography variant="subtitle1">
        <FormattedMessage id="forgotPassword.helpText"/>
      </Typography>
      {errorMessage && (
        <FormattedMessage id={`login.errors.forgotPassword.${errorMessage}`}>
          {txt => <FormHelperText error={true}>{txt}</FormHelperText>}
        </FormattedMessage>
      )}
      <ForgotPasswordForm handleSubmit={async (values) => handleSubmit(values)} isSubmitting={isLoading}/>
    </Layout>
  }

  if (view === 'confirmCode') {
    return <Layout header={Header}>
      <Typography variant="subtitle1">
        <FormattedMessage id="forgotPassword.confirmCodeHelp"
                          defaultMessage={'Please check your email and enter the confirmation code.'}/>
      </Typography>
      {errorMessage && (
        <FormattedMessage id={`login.errors.forgotPassword.${errorMessage}`}>
          {txt => <FormHelperText error={true}>{txt}</FormHelperText>}
        </FormattedMessage>
      )}
      <ForgotPasswordConfirmationForm
        username={username} handleSubmit={handleConfirmation} isSubmitting={isLoading}/>
    </Layout>
  }

  if (view === 'success') {
    return <Layout header={Header}>
      <Typography variant="subtitle1">
        <FormattedMessage id="forgotPassword.confirmCodeHelp"
                          defaultMessage={'You\'ve successfully reset password.'}/>{' '}
        <Link to="/login" variant="subtitle1" component={RouterLink}>
          <FormattedMessage id="forgotPassword.confirmCodeHelp" defaultMessage={'Please sign in'}/></Link>
      </Typography>
    </Layout>
  }
}
