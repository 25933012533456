export default {
  'en-GB': {
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    currentPassword: 'Current password',
    newPassword: 'Enter a new password',
    confirmationCode: 'Confirmation code',
    name: 'Name',
    dateOfBirth: 'Date of Birth',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    selectPrompt: 'Select...',
    formValidations: {
      required: 'Please fill out this field',
      fieldNumber: 'Must be a valid number',
      integer: 'Cannot contain decimals',
      // eslint-disable-next-line no-template-curly-in-string
      minNumber: 'Must be greater than or equal to ${min}',
      // eslint-disable-next-line no-template-curly-in-string
      maxNumber: 'Must be less than or equal to ${max}',
      // eslint-disable-next-line no-template-curly-in-string
      maxLength: 'Must be shorter than ${max} characters',
      invalidEmail: 'The email address is invalid',
      securePassword: 'Now, that\'s a secure password.',
      insecurePassword:
        'Password must contain an {upperCaseLetter} and a {lowerCaseLetter} and a {number} and a {specialCharacter} , and be minimum of 8 {characters}',
      upperCaseLetter: 'uppercase letter',
      lowerCaseLetter: 'lowercase letter',
      number: 'number',
      specialCharacter: 'special character',
      characters: 'characters',
      nameTooShort: 'Name is too short',
      nameTooLong: 'Name is too long',
      passwordTooShort: 'Password is too short',
      atLeastOneNumber: 'Requires numbers',
      atLeastOneSpecialChar: 'Requires special character',
      atLeastOneLowercase: 'Requires lowercase letters',
      atLeastOneUppercase: 'Requires uppercase letters',
      passwordDoesntMatch: 'Password doesn\'t match',
    },
    login: {
      greeting: 'Welcome back',
      text: 'Log in',
      signUpLink: 'Don\'t have an account? Sign Up',
      forgotPasswordLink: 'Forgot password?',
      errors: {
        NotAuthorizedException: 'That username and password combination is not valid.',
        UserNotFoundException: 'That username and password combination is not valid.',
        forgotPassword: {
          UserNotFoundException: 'Can\'t find the user.',
          CodeMismatchException: 'Invalid verification code provided, please try again.',
          ExpiredCodeException: 'Invalid code provided, please request a code again.',
        },
      },
      button: 'Log in',
      requirePasswordChange:
        'You are required to change password to complete setup.',
    },
    signUp: {
      greeting: 'Let\'s introduce you to a better way...',
      text: 'Sign up',
      loginLink: 'Already signed up? Sign in',
      button: 'Sign up',
      usernameExists: 'Email is already registered',
    },
    forgotPassword: {
      text: 'Forgot password?',
      helpText:
        'Enter the email address you registered with and we will send you a code to confirm and reset password.',
      emailPlaceholder: 'Enter your email address',
      button: 'Send password reset code',
    },
    confirmRegistration: {
      text: 'Confirm email',
      helpText:
        'We have send you an email with the confirmation code. Please enter the code below to finish registration.',
      confirmationCode: 'Enter confirmation code',
      button: 'Confirm registration',
    },
    changePassword: {
      errors: {
        NotAuthorizedException: "Invalid current password"
      }
    },
    patients: {
      new: {
        title: 'Add Patient',
        submitButton: 'Add Patient',
      },
    },
    employees: {
      jobTitle: 'Job Title',
      new: {
        title: 'Add Employee',
        submitButton: 'Add Employee',
      },
      jobTitles: {
        nurse: 'Nurse',
        doctor: 'Doctor',
        other: 'Other',
      },
    },
    buttons: {
      cancel: 'Cancel',
      saveChanges: 'Save changes',
    },
    navs: {
      dashboard: 'Dashboard',
      patients: 'Patients',
      employees: 'Employees',
      users: 'Users',
    },
    inviteUser: 'Invite User',
  },
  ml: {
    email: 'ഇമൈല്',
    password: 'പാസ്‌വേഡ്',
    formValidations: {
      required: 'ദയവായി ഈ ഫീൽഡ് പൂരിപ്പിക്കുക',
      invalidEmail: 'ഇമെയിൽ വിലാസം അസാധുവാണ്',
      tooShort: 'തീരെ ചെറുത്',
      securePassword: 'അത് ഒരു സുരക്ഷിത പാസ്‌വേഡാണ്',
      insecurePassword:
        'പാസ്വേഡിൽ ഒരു {upperCaseLetter}, ഒരു {lowerCaseLetter}, {number}, {specialCharacter} എന്നിവ ഉണ്ടായിരിക്കണം, കുറഞ്ഞത് 8 {characters}',
      upperCaseLetter: 'വലിയ അക്ഷരം',
      lowerCaseLetter: 'ചെറിയ അക്ഷരം',
      number: 'അക്കം',
      specialCharacter: 'പ്രത്യേക അക്ഷരം',
      characters: 'അക്ഷരങളായിരിക്കണം',
    },
    login: {
      greeting: 'തിരികെ സ്വാഗതം.',
      text: 'ലോഗിൻ',
      signUpLink: 'ഇവിടെ ആദ്യമായിട്ടാണോ?',
      forgotPasswordLink: 'പാസ്‌വേഡ് മറന്നോ?',
      error: 'ക്ഷമിക്കണം, ആ ഇമെയിലോ പാസ്വേഡോ തെറ്റാണ്',
      button: 'ലോഗിൻ ചെയ്യുക',
    },
    signUp: {
      greeting: 'താങ്കളെ ഒരു മികച്ച മാർഗത്തിലേക്ക് പരിചയപ്പെടുത്താം ...',
      text: 'രജിസ്റ്റർ ചെയ്യുക',
      loginLink: 'ഇതിനകം അക്കൗണ്ട് ഉണ്ട്?',
      button: 'രജിസ്റ്റർ ചെയ്യുക',
      usernameExists: 'ഇമെയിൽ ഇതിനകം രജിസ്റ്റർ ചെയ്തിട്ടുണ്ട്',
    },
    forgotPassword: {
      text: 'പാസ്‌വേഡ് മറന്നോ?',
      helpText:
        'വിഷമിക്കേണ്ട, നിങ്ങൾ രജിസ്റ്റർ ചെയ്ത ഇമെയിൽ വിലാസം നൽകുക, പാസ്വേഡ് മാറ്റാൻ നിങ്ങൾക്ക് ഒരു ലിങ്ക് അയയ്ക്കും.',
      emailPlaceholder: 'നിങ്ങളുടെ ഇമെയിൽ വിലാസം നൽകുക',
      button: 'പാസ്വേഡ് മാറ്റാൻ ലിങ്ക് അയയ്ക്കുക',
    },
  },
}
