import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {Logger} from 'aws-amplify'
import Layout from 'components/layouts/WelcomePageLayout'
import LoaderButton from 'components/atoms/LoaderButton'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import {Formik} from 'formik'
import * as Yup from 'yup'
import Auth from 'lib/auth'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {FormHelperText, TextField} from '@material-ui/core'
import {passwordSchema} from 'lib/schemas'

const logger = new Logger('CompleteNewPasswordForm')

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginBottom: 0,
  },
}))

const NewPasswordSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'formValidations.passwordDoesntMatch')
    .required('formValidations.required'),
})

export default function CompleteNewPasswordForm() {
  const classes = useStyles()

  const [isLoading, setIsLoading] = React.useState(false)
  const {user, signIn} = React.useContext(AuthenticationContext)

  async function handleSubmit(values) {
    setIsLoading(true)

    try {
      await Auth.completeNewPassword(user, values.password)
      await signIn()
    } catch (e) {
      logger.error(e)
      setIsLoading(false)
    }
  }

  return (
    <Layout header={Header}>
      <Formik
        validationSchema={NewPasswordSchema}
        initialValues={{password: '', confirmPassword: ''}}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Password
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              classes={classes}
            />
            <ConfirmPassword
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              classes={classes}
            />
            <LoaderButton
              type="submit"
              isLoading={isLoading}
              loadingText="Saving…"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save Password
            </LoaderButton>
          </form>
        )}
      </Formik>
    </Layout>
  )
}

const Header = ({className}) => (
  <div className={className}>
    <Typography variant="h5" component="h1">
      <FormattedMessage id="login.greeting" />
    </Typography>
    <p>
      <FormattedMessage id="login.requirePasswordChange" />
    </p>
  </div>
)

const Password = injectIntl(
  ({handleChange, handleBlur, values, classes, errors, touched, intl}) => {
    return (
      <>
        <TextField
          type="password"
          id="password"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          label={intl.formatMessage({id: 'password'})}
          value={values.password}
          autoComplete="new-password"
          onChange={handleChange}
          className={classes.formControl}
          onBlur={handleBlur}
        />
        {errors.password && touched.password && (
          <FormHelperText error>
            {intl.formatMessage({id: errors.password})}
          </FormHelperText>
        )}
      </>
    )
  },
)

const ConfirmPassword = injectIntl(
  ({classes, handleChange, handleBlur, values, errors, touched, intl}) => {
    return (
      <>
        <TextField
          type="password"
          id="confirmPassword"
          label={intl.formatMessage({id: 'confirmPassword'})}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          className={classes.formControl}
          autoComplete="new-password"
          fullWidth
          required
        />
        {errors.confirmPassword && touched.confirmPassword && (
          <FormHelperText error>
            {intl.formatMessage({id: errors.confirmPassword})}
          </FormHelperText>
        )}
      </>
    )
  },
)
