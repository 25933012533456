import LoaderButton from '../../atoms/LoaderButton'
import {FormattedMessage, injectIntl} from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import {Link as RouterLink} from 'react-router-dom'
import {Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {FormHelperText, makeStyles} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

const ForgotPasswordSchema = Yup.object().shape({
  username: Yup.string().required('formValidations.required').email('formValidations.invalidEmail'),
})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ForgotPasswordForm({handleSubmit, isSubmitting}) {
  const classes = useStyles()
  return <Formik
    validationSchema={ForgotPasswordSchema}
    initialValues={{username: ''}}
    onSubmit={handleSubmit}
  >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Username values={values} errors={errors} touched={touched} handleChange={handleChange}
                  handleBlur={handleBlur}/>
        <LoaderButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loadingText="Submitting..."
          isLoading={isSubmitting}
        >
          <FormattedMessage id="forgotPassword.button"/>
        </LoaderButton>
        <Grid container>
          <Grid item xs>
            <Link to="/login" variant="body2" component={RouterLink}>
              <FormattedMessage id="forgotPassword.backToLogin" defaultMessage="Back to login"/>
            </Link>
          </Grid>
        </Grid>
      </form>
    )}</Formik>
}

const Username = injectIntl(({values, errors, touched, handleChange, handleBlur, intl}) => {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="username"
        autoComplete="email"
        autoFocus
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.username && touched.username && (
        <FormHelperText error>
          {intl.formatMessage({id: errors.username})}
        </FormHelperText>
      )}
    </>
  )
})
