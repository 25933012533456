import React, {useEffect, useState} from 'react'
import {always, cond, isEmpty, isNil, not, or, T} from 'ramda'
import * as Api from 'lib/api'
import {EditProductForm} from "components/organisms/products"

export default function EditProductPage({history, match}) {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [product, setProduct] = useState(null)

  useEffect(() => {
    async function fetchData() {
      var result = await Api.getProductById(match.params.id)
      result.matchWith({
        Ok: ({value}) => {
          setProduct(value)
        },
        Error: ({value}) => {
          setErrorMessage(value.message)
        },
      })
      setIsLoading(false)
    }

    fetchData()
  }, [match.params.id])

  const handleSubmit = async (values) => {
    var result = await Api.updateProduct(product.id, values)
    result.matchWith({
      Ok: async ({value}) => {
        history.push(`/products/${value.id}`)
      },
      Error: ({value}) => {
        setErrorMessage(value.message)
      },
    })
  }

  const renderContent = cond([
    [({isLoading}) => isLoading, always(<h2>Loading...</h2>)],
    [({errorMessage}) => not(or(isNil(errorMessage), isEmpty(errorMessage))), always(<h2>Something
      went wrong!</h2>)],
    [T, ({product}) => <EditProductForm initialValues={product} onSubmit={handleSubmit}/>],
  ])

  return renderContent({isLoading, errorMessage, product})
}
