import React from 'react'
import {Card, CardContent, Typography} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  icon: {
    fontSize: "4rem"
  }
}))

export function EmptyResources({message, children}) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <NotInterestedIcon className={classes.icon} />
        <Typography variant="h5" component="h2" paragraph>{message}</Typography>
        {children}
      </CardContent>
    </Card>
  )
}
