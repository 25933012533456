import {getAll, getById, post, deleteById, putById} from './common'

const baseUrl = process.env.REACT_APP_API_URL
const employeesUrl = baseUrl + '/employees'

export const getEmployees = async page => {
  return await getAll(employeesUrl, page)
}

export const createEmployee = async employee => {
  return await post(employeesUrl, employee)
}

export const getEmployeeById = async id => {
  return await getById(employeesUrl, id)
}

export const deleteEmployee = async id => {
  return await deleteById(employeesUrl, id)
}

export const updateEmployee = async (id, employee) => {
  return await putById(employeesUrl, id, employee)
}
