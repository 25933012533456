import {getAll, getById, post, deleteById, putById} from './common'

const baseUrl = process.env.REACT_APP_API_URL
const patientsUrl = baseUrl + '/patients'

export const getPatients = async page => {
  return await getAll(patientsUrl, page)
}

export const getPatientById = async id => {
  return await getById(patientsUrl, id)
}

export const createPatient = async patient => {
  return await post(patientsUrl, patient)
}

export const deletePatient = async id => {
  return await deleteById(patientsUrl, id)
}

export const updatePatient = async (id, patient) => {
  return await putById(patientsUrl, id, patient)
}
