import React from 'react'
import {Button, makeStyles, Paper} from '@material-ui/core'
import {Formik} from 'formik'
import {prop} from 'ramda'
import FormHelperText from '@material-ui/core/FormHelperText'
import Toolbar from '@material-ui/core/Toolbar'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import * as Yup from 'yup'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3, 2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}))

const editProductSchema = Yup.object().shape({
  name: Yup.string().required().max(60),
  brand: Yup.string().max(30),
  quantity: Yup.number().typeError().integer().min(0).max(9999999),
})

export default function EditProductForm({initialValues, onSubmit, errorMessage}) {
  const classes = useStyles()

  return <Paper className={classes.root}>
    <Formik validationSchema={editProductSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}>
      {({handleSubmit, handleChange, handleBlur, errors, touched, values}) =>
        <form onSubmit={handleSubmit} noValidate>
          <Name autoFocus onChange={handleChange} onBlur={handleBlur}
                error={prop('name', errors)}
                touched={prop('name', touched)}
                value={prop('name', values)}/>
          <Brand onChange={handleChange} onBlur={handleBlur}
                 error={prop('brand', errors)}
                 touched={prop('brand', touched)}
                 value={prop('brand', values)}/>
          <Quantity type="number" step={1} onChange={handleChange}
                    onBlur={handleBlur}
                    error={prop('quantity', errors)}
                    touched={prop('quantity', touched)}
                    value={prop('quantity', values)}/>
          {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
          <Toolbar disableGutters>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Save changes
            </Button>
            <Button
              component={Link}
              to={`/products/${initialValues.id}`}
              variant="contained"
              className={classes.button}
            >
              <FormattedMessage id="buttons.cancel"/>
            </Button>
          </Toolbar>
        </form>}
    </Formik>
  </Paper>
}

function Name({error, touched, ...props}) {
  return <TextField
    required
    id="name"
    label="Name"
    margin="normal"
    fullWidth
    helperText={error && touched && error}
    FormHelperTextProps={{error: true}}
    {...props}
  />
}

function Brand({error, touched, ...props}) {
  return <TextField
    id="brand"
    label="Brand"
    margin="normal"
    fullWidth
    helperText={error && touched && error}
    FormHelperTextProps={{error: true}}
    {...props}
  />
}

function Quantity({error, touched, ...props}) {
  return <TextField
    id="quantity"
    label="Quantity"
    margin="normal"
    fullWidth
    helperText={error && touched && error}
    FormHelperTextProps={{error: true}}
    {...props}
  />
}
