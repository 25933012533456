import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {Statistic, StatisticLabel, StatisticValue} from 'components/atoms/Statistic'

const useStyles = makeStyles(theme => ({
  actionsBar: {
    padding: theme.spacing(2, 0),
  },
}))

export default function ProductDetail({product}) {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="space-between" className={classes.actionsBar}>
        <Grid item><Typography variant="h6">{product.name}</Typography></Grid>
        <Grid item><Button variant="contained" size="small" color="primary" component={Link}
                           to={`/products/${product.id}/edit`}>Edit Product</Button></Grid>
      </Grid>
      <Card>
        <CardContent>
          <Statistic>
            <StatisticLabel>Brand</StatisticLabel>
            <StatisticValue>{product.brand}</StatisticValue>
          </Statistic>
          <Statistic>
            <StatisticLabel>Num of stock</StatisticLabel>
            <StatisticValue>{product.quantity}</StatisticValue>
          </Statistic>
        </CardContent>
      </Card>
    </div>
  )
}
