import React from 'react'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Auth from 'lib/auth'
import {AuthenticationContext} from 'components/providers/AuthenticationProvider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {faSignOutAlt, faSlidersH, faCaretDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {makeStyles} from '@material-ui/core'

const profileMenu = 'profile-menu'
const useStyles = makeStyles(() => ({
  root: {
    height: '64px',
    display: 'flex',

    '& .MuiButtonBase-root:hover': {
      borderRadius: 0,
    },
  },
}))

function AppBarProfileNavItem({history}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const authContext = React.useContext(AuthenticationContext)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  async function handleLogout() {
    authContext.signOut()
    await Auth.signOut()
  }

  function handleSettings() {
    history.push('/me/settings')
    handleClose()
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return <div className={classes.root}>
    <Button aria-controls={profileMenu} aria-haspopup="true" onClick={handleClick}>
      <Avatar>{authContext.user.username.substr(0, 1)}</Avatar>
      <FontAwesomeIcon icon={faCaretDown} size="lg" style={{marginLeft: '2px'}}/>
    </Button>
    <Menu
      id={profileMenu}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleSettings}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faSlidersH} size="lg"/>
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faSignOutAlt} size="lg"/>
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  </div>
}


export default withRouter(AppBarProfileNavItem)
