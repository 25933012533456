import React from 'react'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LoaderButton from 'components/atoms/LoaderButton'
import {Link as RouterLink, withRouter} from 'react-router-dom'
import Link from '@material-ui/core/Link'
import {FormattedMessage} from 'react-intl'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export function SignUpForm({onSubmit}) {
  const classes = useStyles()

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    const {email, password} = event.target.elements

    setIsLoading(true)
    onSubmit({email: email.value, password: password.value})
    setIsLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Email />
        <Password />
        <LoaderButton
          type="submit"
          isLoading={isLoading}
          fullWidth
          variant="contained"
          color="primary"
          loadingText="Signing up…"
          className={classes.submit}
        >
          Sign Up
        </LoaderButton>
      </form>
      <Grid container justify="flex-end">
        <Grid item>
          <Link to="/login" variant="body2" component={RouterLink}>
            <FormattedMessage id="signUp.loginLink" />
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

function Email() {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      autoFocus
    />
  )
}

function Password() {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="password"
      autoComplete="new-password"
    />
  )
}

export default withRouter(SignUpForm)
