import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import {Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  editButton: {
    // backgroundColor: ''
  },
  deleteButton: {
    // backgroundColor: ''
  },
}))

export function UserDetailPage({api, match, history}) {
  const {id} = match.params
  const [user, setUser] = useState({})
  const classes = useStyles()
  useEffect(() => {
    async function fetchData() {
      var data = await api.getUserById(id)
      setUser(data)
    }
    fetchData()
  }, [api, id])

  return (
    <Toolbar>
      <Typography variant="h5" component="h2" className={classes.title}>
        {user.name}
      </Typography>
      <div>
        <Button
          component={Link}
          to={'/users/' + id + '/update'}
          variant="contained"
          className={clsx(classes.button, classes.editButton)}
        >
          Edit
        </Button>
        <Button
          onClick={() => {}}
          variant="contained"
          color="secondary"
          className={clsx(classes.button, classes.deleteButton)}
        >
          Delete
        </Button>
      </div>
    </Toolbar>
  )
}
